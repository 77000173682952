import React from 'react'
import {Helmet} from 'react-helmet'

const SEO = ({title}) => {
  return(
    <Helmet
      title={title}
      titleTemplate='%s | Restless Monks'
    />
  )
}

SEO.defaultProps = {
  title: 'Restless Monks'
}

export default SEO