import React from 'react'
import SEO from './SEO'
import particlesOptions from '../particles'
import Particles from 'react-tsparticles'
import Header from './Header'
import PropTypes from 'prop-types'

const Template = ({
  children, 
  title, 
  topPaddingLess, 
  activeTab
}) => {
  return(
    <div className={"hero is-fullheight "}>
      <SEO 
        title={title}
      />
      <Particles 
        options={particlesOptions} 
      />
      <Header 
        activeTab={activeTab}
      />
      <div className={"hero-body pt-0 mt-0 "+ (topPaddingLess && "top-padding-less")}>
        {children}
      </div>
    </div>
  )
}

Template.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  topPaddingLess: PropTypes.bool,
  activeTab: PropTypes.number
}

Template.defaultProps = {
  title: "Restless Monks",
  topPaddingLess: false,
  activeTab: 0
}

export default Template