import { Link } from 'gatsby'
import React from 'react'

type NavLink = {
  id: number,
  name: string,
  link: string
}

const links: NavLink[] = [
  {
    id: 1,
    name: 'Home',
    link: '/'
  },
  {
    id: 2,
    name: 'Projects',
    link: '/work'
  },
  {
    id: 3,
    name: 'team',
    link: '/team'
  }
]

const Header = ({activeTab}) => {

  return(
    <div className="hero-head">
      <div className="section pt-4 pb-5">
        <div className="columns is-mobile is-centered">
          <div className="column is-narrow has-text-centered">
            <img 
              className="image-logo"
              alt="logo"
              src={'/favicon.ico'}
            />
            <div className="buttons has-addons header-button-group">
              {links.map((item, index) => (
                <Link 
                  key={index}
                  to={item.link}
                  className={"button is-family-primary header-button is-rounded is-uppercase is-cyan " + (activeTab === item.id ? "selected-button has-text-weight-bold " : "has-text-weight-normal ") + (activeTab!==item.id ? "has-text-black" : "has-text-white")}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header